import React from "react";
import {render} from "react-dom";

import App from "../components/App";

import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

window.addEventListener("load", () => {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then(registration => {
      console.log("ServiceWorker registered: ", registration);

      // window.Notification.requestPermission().then(permission => {
      //   if (permission !== "granted") {
      //     throw new Error("Permission not granted for Notification");
      //   }
      // });

      // check if the browser supports notifications
      if (!("Notification" in window)) {
        console.error("This browser does not support desktop notification");
      }
      // check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        console.log("Permission to receive notifications has been granted");
      }
      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission(function(permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            console.log("Permission to receive notifications has been granted");
          }
        });
      }

      var serviceWorker;
      if (registration.installing) {
        serviceWorker = registration.installing;
        console.log("Service worker installing.");
      } else if (registration.waiting) {
        serviceWorker = registration.waiting;
        console.log("Service worker installed & waiting.");
      } else if (registration.active) {
        serviceWorker = registration.active;
        console.log("Service worker active.");
      }
    })
    .catch(registrationError => {
      console.log("Service worker registration failed: ", registrationError);
    });
});

document.addEventListener("DOMContentLoaded", () => {
  render(
    <GoogleReCaptchaProvider
      reCaptchaKey="XXXXX666666LeEfTgaAAAAAD6PeR_myWq6e0h0iC1fiodWExFV"
      language="fr"
      useRecaptchaNet="0"
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined
      }}
    >
      <App />
    </GoogleReCaptchaProvider>,
    document.querySelector("#app")
  );
});